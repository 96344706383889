import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GlobalHelperService } from 'src/app/globalHelper/global-helper.service';
import { NumericFields } from 'src/shared/models/numeric-fields';
import { NumericValidation } from 'src/shared/models/numeric-validation';

@Component({
  selector: 'app-numeric',
  templateUrl: './numeric.component.html',
})
export class NumericComponent implements OnChanges {

  numericValidation: any = null;
  numericField: any = null;
  numeralFormat = '';
  isPercentage = false;
  inputValue = '';

  @Input()
  format = '';

  @Input()
  type = '';

  @Input()
  numericType :any;

  @Input()
  disabled:any = false;

  @Input() readonly: boolean;

  @Input()
  placeholder = '';

  @Input()
  value: string|any;

  @Output()
  selectedValue: EventEmitter<string> = new EventEmitter<string>();
  additionalSymbol: any='';

  constructor(private globalHelperService:GlobalHelperService) {
    this.numericField = new NumericFields();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.value) {
      const change = changes.value;

      if (change.currentValue !== change.previousValue) {
        this.onChange(change.currentValue);
      }

    }
  }

  setNumeralFormat(): void {
    this.additionalSymbol = '';
    switch (this.format) {
      case 'percentage' :
        this.numeralFormat = '(0,0.0%)';
        this.isPercentage = true;
        break;
      case 'number' :
        this.numeralFormat = '0';
        break;
      case 'currency' :
        if(this.globalHelperService.currentAccessPage=="NewDealForm"||this.globalHelperService.currentAccessPage=="ClosingChecklistForm"||this.globalHelperService.currentAccessPage=="DealWBSForm"){
          this.numeralFormat = '($0,0.0)';
        }
      else{
        this.numeralFormat = '($0,0)';
      }
        break;
      case 'currencyWithDecimal' :
      this.numeralFormat = '($0,0.0)';
      break;
      case 'multiply':
        this.numeralFormat='(0,0.0)';
        this.additionalSymbol = 'x';
        break;
    }
  }

  formatInitialValue(): string {
    const numericInitialValue = Number(this.validateNumber(this.numericField.numericInitialValue));

    if (this.isPercentage) {
      this.numericValidation = new NumericValidation(numericInitialValue / 100, this.numeralFormat);
    } else {
      this.numericValidation = new NumericValidation(numericInitialValue, this.numeralFormat);
    }

    return this.numericValidation.formatNumber();
  }

  validateNumber(value:string):string{
    let inputValue = Number(value)
    
    if(!isFinite(inputValue)){
      return '0';
    }
    else{
      return value;
    }
  }

  onChange(value: any): void {
    if(value === null || value === '' || value === undefined){
      this.numericField.numericInitialValue ='';
      this.numericField.numericFormattedValue='';
      this.inputValue =  this.numericField.numericFormattedValue+this.additionalSymbol;
      this.selectedValue.emit(this.numericField.numericInitialValue);
    }
    else if(isNaN(value)){
      if(this.validateNA(value)){
      this.numericField.numericInitialValue ='N/A';
      this.numericField.numericFormattedValue='N/A';
      this.inputValue =  this.numericField.numericFormattedValue+this.additionalSymbol;
      this.selectedValue.emit(this.numericField.numericInitialValue);
      }
      else{
        //This is added for opportunityID field in dealwbs
        //Same field is numeric in dealwbs form but freetext in newdeal form
        //Below change will allow the binding for string values if any added from newdealform
        if(this.globalHelperService.currentAccessPage=="DealWBSForm")
        {
          this.setNumeralFormat();
          this.numericField.numericInitialValue = value;
          this.numericField.numericFormattedValue =
            this.numeralFormat === ''
              ? this.numericField.numericInitialValue
              : this.formatInitialValue();
          this.inputValue = this.numericField.numericFormattedValue;
          this.selectedValue.emit(this.numericField.numericInitialValue);
        }
        else
        {
          this.numericField.numericInitialValue ='';
          this.numericField.numericFormattedValue='';
          this.inputValue =  this.numericField.numericFormattedValue+this.additionalSymbol;
          this.selectedValue.emit(this.numericField.numericInitialValue);
        }
      }
    }
    else{
      this.setNumeralFormat();

      this.numericField.numericInitialValue = value;
      this.numericField.numericFormattedValue =
        this.numeralFormat === ''
          ? this.numericField.numericInitialValue
          : this.formatInitialValue();
          if(this.numericField.numericFormattedValue==undefined){
              this.numericField.numericFormattedValue= this.format=="currencyWithDecimal"?"$0.0": this.format=="currency"?"$0":
              this.format=="percentage"?"0.0%":this.format=="multiply"?"0.0":"0";
          }

      this.inputValue = this.numericField.numericFormattedValue+this.additionalSymbol;
      this.selectedValue.emit(this.numericField.numericInitialValue);
    }
  }

  onFocus(event:any): void {
    this.inputValue = this.numericField.numericInitialValue;
    const length = event.target.value.length;
    event.target.setSelectionRange(length, length);
  }

  validateNA(value:any):boolean{
    if(value == 'n/a' || value == 'N/a' || value == 'N/A' || value == 'n/A'){
      return true;
    }
    else{
      return false;
    }
  }

  onBlur(): void {
    if(this.format == 'multiply')
      this.inputValue = this.numericField.numericFormattedValue+this.additionalSymbol;
      else
      this.inputValue = this.numericField.numericFormattedValue;
  }
}
