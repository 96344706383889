import { Injectable } from '@angular/core';
import { EngagementDashboardtItem } from '../../models/engagement-dashboard-item';
import { AppSettings } from 'src/shared/app-settings';
import { EngagementStatusValues } from '../../models/engagement-status-values';
import { EngagementStatusColors } from '../../models/engagement-status-colors';
import { FormStatusValue } from 'src/shared/models/form-status-value';
import { ComplianceStatusValues } from '../../models/compliance-status-values';
import { ComplianceStatusColors } from '../../models/compliance-status-colors';

@Injectable({
  providedIn: 'root'
})

export class EngagementDashboardServiceHelper {

  constructor() {}

  mapPageSizeValues(response:any): Array<number> {
    let valueList = response.map((value:any) => {
      return value.Value;
    });
    valueList = this._addPageSizeDefaultValue(valueList);
    valueList = this._sortPageSizeList(valueList);
    return valueList;
  }

  createEngagementDashboardObject(
    engagementId: number,
    engagementName: string,
    status: string,
    submissionDate: Date,
    clientName: string,
    itemId: number,
    complianceStatus: string,
    adminAssigned: string,
    adminUserName: string,
    myDealId: number, formStatus:string): EngagementDashboardtItem {

      const engStatus = this._calculateEngagementStatus(submissionDate, status);
      const engStatusColor = this._calculateEngagementStatusColor(engStatus);
      const compStatus = complianceStatus ? complianceStatus : ComplianceStatusValues.NA;
      const compStatusColor = this._calculateComplianceStatusColor(compStatus);
      const engagementDashboardItem: EngagementDashboardtItem = {
        engagementId: +engagementId + +AppSettings.engagementIdInitialValue,
        engagementName: engagementName ? engagementName : '',
        status,
        submissionDate,
        newDealItemId: engagementId,
        clientName: clientName ? clientName : '',
        engagementStatus: engStatus,
        engagementStatusColor: engStatusColor,
        engagementHealthObjectItemId: itemId,
        complianceStatus: compStatus,
        complianceStatusColor: compStatusColor,
        adminAssigned : adminAssigned == '' ? "Admin not yet assigned" : adminAssigned,
        adminUserName: adminUserName,
        myDealId : myDealId,
        formTitle:this.getFormTitle(status, formStatus)
      };
      return engagementDashboardItem;
  }

  getFormTitle(status:string, formStatus:string){  
    let formStatusList:any = []; 
    if((status != null && status != AppSettings.initialFormStatus) || formStatus!=null){
      formStatusList.push(AppSettings.newDealSetupForm);
    }
    return formStatusList
  }

  private _calculateComplianceStatusColor(complianceStatus: string): any {
    switch (complianceStatus) {
      case ComplianceStatusValues.Issues:
        return ComplianceStatusColors.Issues;
      case ComplianceStatusValues.NoIssues:
        return ComplianceStatusColors.NoIssues;
      case ComplianceStatusValues.NA:
        return ComplianceStatusColors.NA;
    }
  }

  private _calculateEngagementStatus(submissionDate: Date, formStatus: string): string {
    let status: string;
    if (submissionDate) {
      status = EngagementStatusValues.Pursuit;
    } else {
      status = EngagementStatusValues.NotSubmitted;
    }
    if (formStatus === FormStatusValue.discontinued) {
      status = EngagementStatusValues.DealLost;
    }
    return status;
  }

  private _calculateEngagementStatusColor(engagementStatus:any): any {
    switch (engagementStatus) {
      case EngagementStatusValues.NotSubmitted:
        return EngagementStatusColors.NotSubmitted;
      case EngagementStatusValues.Pursuit:
        return EngagementStatusColors.Pursuit;
      case EngagementStatusValues.DealWon:
        return EngagementStatusColors.DealWon;
      case EngagementStatusValues.DealLost:
        return EngagementStatusColors.DealLost;
      case EngagementStatusValues.DealClosed:
        return EngagementStatusColors.DealClosed;
    }
  }

  private _addPageSizeDefaultValue(valueList: Array<number>): Array<number> {
    if (!valueList.find(v => v === AppSettings.pageSizeDefault)) {
      valueList.push(AppSettings.pageSizeDefault);
    }
    return valueList;
  }

  private _sortPageSizeList(valueList: Array<number>): Array<number> {
    return valueList.sort((a, b) => a - b);
  }
}
