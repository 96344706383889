export class EngagementDashboardtItem {
  engagementId: number;
  engagementName: string;
  status: string;
  submissionDate: Date;
  engagementStatus: string;
  engagementStatusColor: string;
  newDealItemId: number;
  clientName: string;
  engagementHealthObjectItemId: number;
  complianceStatus: string;
  complianceStatusColor: string;
  adminAssigned : string;
  adminUserName: string;
  myDealId: number;
  formTitle:string[];
}
export class AvailableFormItem {
  availableFormItemId: number;
  availableFormTitle: string;
  availableFormListName: string;
  availableFormSiteUrl: string;
  availableFormPageUrl: string;
  availableAltFormPageUrl: string;
  availableFormCreated: boolean;
  availableFormSidebar: boolean;
  availableFormModal: boolean;
  itemId: number;
  formStatus: string;
}
export class FormStatusObjectItem {
  status: string;
  Id: string;
}