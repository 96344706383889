import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { EngagementHealthObjectService } from '../shared/services/engagement-health-object.service';
import { HeaderComponent } from '../header/header.component';
import { DealTeamDetailsComponent } from '../deal-team-details/deal-team-details.component';
import { SidebarEngagementContentBreakdownComponent } from '../sidebar-engagement-content-breakdown/sidebar-engagement-content-breakdown.component';
import { EngagementHealthDetailsComponent } from '../engagement-health-details/engagement-health-details.component';
import { EngagementHealthObjectItem } from '../models/engagement-health-object-item';
import { FilesService } from 'src/shared/services/files.service';
import { AppSettings } from 'src/shared/app-settings';
import { EngagementHealthObjectServiceHelper } from '../shared/services/engagement-health-object-helper';
import { AttachmentItem } from 'src/shared/models/attachment-item';
import { MainService } from 'src/shared/services/main.service';
import { CurrentUser } from 'src/shared/models/current-user-data';
import { DealTeamItem } from 'src/shared/models/deal-team-item';
import { ComplianceData } from '../models/compliance-data';
import { AvailableFormItem } from 'src/shared/models/available-form-item';
import { ModalComponent } from 'src/shared/components/modal/modal.component';
import { MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DealTeamAuditlogComponent } from '../deal-team-auditlog/deal-team-auditlog.component';
import { PeoplePickerSelectedRow } from 'src/shared/components/peoplepicker-dynamic-table/people-picker-selected-row.interface';
import { DealTeamObject } from '../models/dealteam-object';
import { DynamicTableAction } from 'src/shared/models/dynamic-table-action';
import { PeoplepickerDynamicTableItem } from 'src/shared/models/peoplepicker-dynamic-table-item';
import { FormGroup } from '@angular/forms';
import { CustomFormControl } from 'src/shared/custom/custom-form-control';
import { DealTeamTableComponent } from '../deal-team-table/deal-team-table.component';
import { ThirdPartyCodesComponent } from '../third-party-codes/third-party-codes.component';
import { NewdealService } from 'src/app/services/newdeal.service';
import { forkJoin } from 'rxjs';
import { EngagementHealthObjectServiceService } from 'src/app/services/engagement-health-object-service.service';
import { DropdownItem } from 'src/shared/models/dropdown-item';
import { PeoplePickerUser } from 'src/shared/models/people-picker-user';
import { DropdownService } from 'src/shared/services/dropwdown.service';
import { CommonHelperService } from 'src/app/commonHelper/common-helper.service';
import { NewDealNotifyPopupComponent } from 'src/shared/components/alerts/newdeal-notify-popup/newdeal-notify-popup.component';
import { SpinnerService } from 'src/shared/services/spinner.service';
import { GlobalHelperService } from 'src/app/globalHelper/global-helper.service';
const GRAPH_ENDPOINT_firstpart = 'https://graph.microsoft.com/v1.0/groups/';
const GRAPH_ENDPOINT_secondpart='/members?$select=displayName,jobTitle,userPrincipalName&$top=200';

export interface UserPermisions {
  isComplianceUser: boolean;
  isDealTeamUser: boolean;
  isAdmin: boolean;
}

const modalTypes  = {
  noAccess: 'access',
  notValidForm: 'no-valid-form'
}

@Component({
  selector: 'app-engagement-health-object',
  templateUrl: './engagement-health-object.component.html',
  styleUrls: ['./engagement-health-object.component.scss']
})
export class EngagementHealthObjectComponent implements OnInit {

  @ViewChild(HeaderComponent)
  headerComponent: HeaderComponent;

  
  @ViewChild(DealTeamAuditlogComponent)
  dealTeamAuditlogComponent: DealTeamAuditlogComponent;

  @ViewChild(DealTeamDetailsComponent)
  dealTeamDetailsComponent: DealTeamDetailsComponent;

  @ViewChild(EngagementHealthDetailsComponent)
  engagementHealthDetailsComponent: EngagementHealthDetailsComponent;
  @ViewChild(ThirdPartyCodesComponent)
  thirdPartyCodesComponent: ThirdPartyCodesComponent;

  @ViewChild(SidebarEngagementContentBreakdownComponent)
  sidebarEngagementContentBreakdownComponent: SidebarEngagementContentBreakdownComponent;

  @ViewChild('EngagementHealth')
  modal: ModalComponent;

  @ViewChild('dealTeam')
  dealTeamComponent: DealTeamTableComponent;

  @ViewChild(NewDealNotifyPopupComponent, { static: true }) notifypopupComponent: NewDealNotifyPopupComponent;

  

  modalType: string;
  formId: number;
  engagementHealthObject: EngagementHealthObjectItem;
  newDealfilesList: Array<any>;
  caffilesList: Array<AttachmentItem>;
  crossBorderfilesList: Array<AttachmentItem>;
  dealTeamValues: Array<PeoplepickerDynamicTableItem> = [];
  formRetrieved: boolean;
  updateDealTeamTable = true;
  adGroupMember:any;
  currentUser: CurrentUser;
  isCurrentUserAdmin:boolean = false;
  currentUserName?:string="";
  appFormGroup: FormGroup;
  myDealFormId: number;
  saveInProgress: boolean;
  firstColumnItemList:any;
  secondColumnItemList:any;
  thirdColumnItemList:any;
  myDealId:any;
  newDealId:string;
  userPermisions: UserPermisions = {
    isComplianceUser: false,
    isDealTeamUser: false,
    isAdmin:false
  };

  

  constructor(private engagementHealthObjectService: EngagementHealthObjectService,
              private route: ActivatedRoute,
              private httpClient: HttpClient,
              private authService:MsalService,
              private fileService: FilesService,
              private engagementHealthObjectServiceHelper: EngagementHealthObjectServiceHelper,
              private mainService: MainService,
            private newDealService:NewdealService,
          private EHOService:EngagementHealthObjectServiceService,
          private dropdownService:DropdownService,
          private commonHelperService: CommonHelperService,
      private spinnerService: SpinnerService,
      public globalHelperService: GlobalHelperService
  ) { }

  ngOnInit() {
    this.spinnerService.showAutoSaveSpinner();
    localStorage.setItem("CurrentAccessPage", "EHO");
     this._setFormId();          
    this.globalHelperService.currentAccessPage="EHO";
    this.appFormGroup = new FormGroup({
        dealteamTable: new CustomFormControl('Deal Team', [])
      });
      if (this.formId) {
       
        this.EHOService.GetEHOObjects(this.formId).subscribe({
          next:(response:any)=>{
             this.myDealId = response[0][AppSettings.myDealSiteColumn];
              if(this.myDealId!= null && this.myDealId != undefined){
         this._retrieveEngagement();
        }
          }
        })
       } else {
         this.redirectOkModal();
       }
    this.currentUserName = localStorage.getItem("NewDealAuditorName")?.toString();
  }
  removeBrowserScroll(){
    document.body.style.overflow="hidden";
  }
  applyBrowserScroll(){
    document.body.style.overflow="auto";
    document.body.style.position="absolute";
  }
  cutLoadingAnimation = () => {
    const fullScrean = document.getElementById('DeltaDelegateControls');
    if (fullScrean) {
      fullScrean.classList.remove('container-loader');
    }
    this.applyBrowserScroll();
    //this.spinnerService.hideAutoSaveSpinner();Commented due to load all the forms
  }

  redirectEngagementHealthList() {

    this.redirectOkModal();
  }

  redirectOkModal() {
    // const ctx = '_spPageContextInfo';
    // const url =  window[ctx].webAbsoluteUrl + '/Lists/' + AppSettings.engagementHealthList;
    // window.open(url, '_self');
    window.location.href=AppSettings.Engagementdashboard;
  }

  updateEngagementHealthData(engagementHealthData: ComplianceData) {
    engagementHealthData.dealTeamItemId=this.myDealFormId;
    this.engagementHealthObjectService.updateEngagementHealthEHO(engagementHealthData,this.engagementHealthObject.engagementCreatedDate);
  }
  private _openModal(modalType: string) {
    this.modalType = modalType;
    this.modal.open();
  }

  private _retrieveEngagement() {
    this.formRetrieved = true;
    this.LoadDropdownLists(); 

forkJoin([this.EHOService.GetAvailableForms(),
  this.engagementHealthObjectService.retrieveEngagementObjectEHO(this.formId,this.myDealId),
  this.engagementHealthObjectService.retrieveFormStatusObjectEHO(AppSettings.crossBorderFormList, this.myDealId),
  this.engagementHealthObjectService.retrieveFormStatusObjectEHO(AppSettings.dealWbsSetupFormList, this.myDealId),
  this.engagementHealthObjectService.retrieveFormStatusObjectEHO(AppSettings.closingChecklistFormList, this.myDealId),
  this.engagementHealthObjectService.getDealTeamUsersEHO(this.myDealId),
 // this.fileService.getFilesDynamic(this.engagementHealthObject.newDealItemId.toString(), newDealBaseUrl, AppSettings.newDealSetupFormList),
  //this.engagementHealthObjectService.updateMyDealEngagementIdEHO(this.formId, this.myDealId)
]).subscribe(([result,engagementObject
  ,statusCrossBorder,statusWbs,statusclosingChecklist
 ,dealTeamList
])=>{
  //debugger;
  this.commonHelperService.getUserData().subscribe(user=>{
    if(user.length !=0){
  this.applyBrowserScroll(); 
  this.spinnerService.hide();
  this.spinnerService.showAutoSaveSpinner();
  const availableForms = this.engagementHealthObjectServiceHelper.createAvailableFormItemsObjects(result);
  const newDealBaseUrl = this._getFormBaseUrl(availableForms, AppSettings.newDealSetupFormList);
  const crossBorderBaseUrl = this._getFormBaseUrl(availableForms, AppSettings.crossBorderFormList);
  const cafBaseUrl = this._getFormBaseUrl(availableForms, AppSettings.cafFormList);
  const dealwbsBaseUrl = this._getFormBaseUrl(availableForms, AppSettings.dealWbsSetupFormList);
  const closingChecklistBaseUrl = this._getFormBaseUrl(availableForms, AppSettings.closingChecklistFormList);
  this.sidebarEngagementContentBreakdownComponent.setAvailableForms(availableForms);
  this.currentUser = user;
  this.isCurrentUserAdmin = this._isDCFAdmin(this.currentUser.groups);
  this.engagementHealthObject = engagementObject;
  this.userPermisions.isAdmin = this.isCurrentUserAdmin;
  if(!this.engagementHealthObject){
    this.modalType = modalTypes.notValidForm;
    this.notifypopupComponent.openPopup("NewDeal",this.modalType);
    this.spinnerService.hideAutoSaveSpinner();
  }
  //this._retrieveOtherFormsStatus(crossBorderBaseUrl, cafBaseUrl, dealwbsBaseUrl, closingChecklistBaseUrl);
  let statusCaf={
    Id:1,
    status:"Pending"
  }      
  const folderUrlCaf:any = this._getFolderUrlCaf(statusCaf);
  const folderUrlCrossBorder:any = this._getFolderCrossBorder(statusCrossBorder);
  // forkJoin([
  //   this.fileService.getFiless(folderUrlCaf, cafBaseUrl, AppSettings.cafFormList),
  //   this.fileService.getFiless(folderUrlCrossBorder, crossBorderBaseUrl, AppSettings.crossBorderFormList)])
  forkJoin([
    this.fileService.getFilesDynamic(statusCrossBorder?.Id.toString(), crossBorderBaseUrl, AppSettings.crossBorderFormList)])
    .subscribe(([crossBorderAttachments]) => {
      this.spinnerService.hideAutoSaveSpinner();
      let cafAttachments:any=[];
    this.caffilesList = this.engagementHealthObjectServiceHelper.mapFilesResponseToObject(
      cafAttachments, folderUrlCaf, cafBaseUrl, AppSettings.cafFormList);
    this.crossBorderfilesList = this.engagementHealthObjectServiceHelper.mapFilesResponseToObject(
      crossBorderAttachments, folderUrlCrossBorder, crossBorderBaseUrl, AppSettings.crossBorderFormList);
    this.sidebarEngagementContentBreakdownComponent.setSidebarPropertiesCaf(this.caffilesList);
    this.sidebarEngagementContentBreakdownComponent.setSidebarPropertiesCrossBorder(this.crossBorderfilesList);
  });
  // if (statusCrossBorder) {
  //   this.sidebarEngagementContentBreakdownComponent.setAvailableFormStatus(AppSettings.crossBorderFormList,
  //     statusCrossBorder.status, statusCrossBorder.Id);
  // }
  // if (statusCaf) {
  //   this.sidebarEngagementContentBreakdownComponent.setAvailableFormStatus(AppSettings.cafFormList, statusCaf.status, statusCaf.Id);
  // }
  if (statusWbs) {
    this.sidebarEngagementContentBreakdownComponent.setAvailableFormStatus(AppSettings.dealWbsSetupFormList,
      statusWbs.status, statusWbs.Id);
  }
  if (statusclosingChecklist) {
    this.sidebarEngagementContentBreakdownComponent.setAvailableFormStatus(AppSettings.closingChecklistFormList,
      statusclosingChecklist.status, statusclosingChecklist.Id);
  }
  const folderUrlNewDeal = this._getFolderUrlNewDeal();
  this.sidebarEngagementContentBreakdownComponent.setAvailableFormStatus(AppSettings.newDealSetupFormList,
    engagementObject.status, engagementObject.newDealItemId);
     //setting up formid and mydealFormId
     this.mainService.myDealFormId = this.engagementHealthObject.myDealId;
     this.mainService.formId = this.formId;
     this.myDealFormId = this.engagementHealthObject.myDealId;
     forkJoin([
      //this.engagementHealthObjectService.getDealTeamUsersEHO(this.engagementHealthObject.myDealId),
      this.fileService.getFilesDynamic(this.engagementHealthObject.newDealItemId.toString(), newDealBaseUrl, AppSettings.newDealSetupFormList),
      this.engagementHealthObjectService.updateMyDealEngagementIdEHO(this.formId, this.engagementHealthObject.myDealId)
    ])
     // .subscribe(([dealTeamList,newDealAttachments,myDealResponse]) => {  
        .subscribe(([newDealAttachments,myDealResponse]) => {  
        //.subscribe(([newDealAttachments]) => {  
        this.userPermisions.isComplianceUser =this._isDCFMemberInAdGroup(this.currentUser.email,JSON.parse((localStorage as any).getItem("adGroupMember")));
        var dealTeamTableList:any=null;
        this.commonHelperService.getUserGroupMembership().subscribe(isInGroup => {
         

          if(isInGroup!=null && isInGroup!=undefined){
             dealTeamTableList = this._loadActualDealTeamList(dealTeamList)
          }      
        });
          
          this.newDealfilesList = this.engagementHealthObjectServiceHelper.mapFilesResponseToObject(
            newDealAttachments, folderUrlNewDeal, newDealBaseUrl, AppSettings.newDealAttachementFolder);
          this._setPermissions(dealTeamList);
          this._setChildComponents(dealTeamList);
          //this object used to handle editable dealTeam Table.
   
          if (dealTeamTableList.length > 1) {
            var dealTeamListsWithOutDuplicates = dealTeamTableList.reduce((accumalator:any, current:any) => {
              if (
                !accumalator.some(
                  (item:any) => item.usersSelected[0].Key === current.usersSelected[0].Key
                    && item.optionsSelectedFirstColumn.key === current.optionsSelectedFirstColumn.key
                    && item.optionsSelectedSecondColumn.key === current.optionsSelectedSecondColumn.key
                    && item.optionsSelectedThirdColumn.key === current.optionsSelectedThirdColumn.key)) {
                accumalator.push(current);
              }
              return accumalator;
            }, []);
            this.dealTeamValues = dealTeamListsWithOutDuplicates;
          } else {
            this.dealTeamValues = dealTeamTableList;
          }
                          
          this.appFormGroup.get('dealteamTable')?.setValue(this.dealTeamValues);
          if(!this.userPermisions.isAdmin && !this.userPermisions.isDealTeamUser)
            {
              this.formRetrieved=false;
              // this._openModal("no-dealteam-member");
              this.modalType = modalTypes.noAccess;
              this.notifypopupComponent.openPopup("NewDeal",this.modalType);
              this.spinnerService.hideAutoSaveSpinner();
            }
    });
 // const crossBorderBaseUrl = this._getFormBaseUrl(availableForms, AppSettings.crossBorderFormList);
  //const cafBaseUrl = this._getFormBaseUrl(availableForms, AppSettings.cafFormList);
  //const dealwbsBaseUrl = this._getFormBaseUrl(availableForms, AppSettings.dealWbsSetupFormList);
  //const closingChecklistBaseUrl = this._getFormBaseUrl(availableForms, AppSettings.closingChecklistFormList);
  //this._retrieveOtherFormsStatus(crossBorderBaseUrl, cafBaseUrl, dealwbsBaseUrl, closingChecklistBaseUrl);
  }
});
});


    

  }

  _loadActualDealTeamList(dealTeamList:any){
    let dealTeamValues=new Array<PeoplepickerDynamicTableItem>();
    let indexValue=0;
    dealTeamList.forEach((x:any)=>{
    let dataObject=new PeoplepickerDynamicTableItem();
    dataObject.optionsSelectedFirstColumn=this.loadDropDownValues(x,"First");
    dataObject.optionsSelectedSecondColumn=this.loadDropDownValues(x,"Second");
    dataObject.optionsSelectedThirdColumn=this.loadDropDownValues(x,"Third");
    dataObject.itemId=x.ID;
    dataObject.usersSelected.push(this.loadDealTeamMember(x,indexValue));
    dealTeamValues.push(dataObject);
    indexValue++;
    })
    return dealTeamValues;
  }

  loadDealTeamMember(data: any,index:any) {
    let obj = {} as PeoplePickerUser;
    obj.DisplayText = data.Name;
    obj.Key = data.ID;
    obj.displayName = data.Name;
    obj.mail=data.Email;//this.loadEmailIdFromObject(responseFormItem,index);
    obj.LeftDCF = this._isInAdGroup(obj.mail, JSON.parse((localStorage as any).getItem("adGroupMember"))!=null?JSON.parse((localStorage as any).getItem("adGroupMember")):this.adGroupMember.value)===undefined?true:false
    return obj;
  }

  
private _isInAdGroup(email: string, group:Array<any>): boolean {
if(group.length != 0 && email != null && email != undefined)
    return group.find(user => user.userPrincipalName.toLowerCase() ===email.toLowerCase());
    else
    return false;
    //return group.find(user => user.Email === email);
  }

  loadDropDownValues(data:any,columnIndex:any){
    const dropdownItem = new DropdownItem();
    if (columnIndex == "First") {
      dropdownItem.key = this.fillingDropdownKeyValues(data,columnIndex);
      dropdownItem.value = data.Role;
    }
    else if (columnIndex == "Second") {
      dropdownItem.key = this.fillingDropdownKeyValues(data,columnIndex);
      dropdownItem.value = data.PursuitRole;
    }
    else {
      dropdownItem.key = this.fillingDropdownKeyValues(data,columnIndex);
      dropdownItem.value = data.SwiftRole;
    }
    return dropdownItem;
  }

  fillingDropdownKeyValues(row:any,columnIndex:any){
    let key: any="";
    if (columnIndex == "First") {
      this.firstColumnItemList.forEach((x: any) => {
        if (x.value == row.Role) {
          key = x.key;
        }
      })
    }
    else if (columnIndex == "Second") {
      this.secondColumnItemList.forEach((x: any) => {
        if (x.value == row.PursuitRole) {
          key = x.key;
        }
      })
    }
    else {
      this.thirdColumnItemList.forEach((x: any) => {
        if (x.value == row.SwiftRole) {
          key = x.key;
        }
      })
    }
    return key;
  }

  LoadDropdownLists(){
    this.dropdownService.fillLookupOptionsNew(AppSettings.dealTeamRole,'Role').then(
      (response:any) => {
        // Success block
       this.firstColumnItemList=response;
      }).catch((error:any) =>{console.error('Error fetching data:', error);})
  
  
  
        this.dropdownService.fillLookupOptionsNew(AppSettings.dealTeamPursuitRole,'Role').then(
      (response:any) => {
        // Success block
        this.secondColumnItemList=response;
      }).catch((error:any) =>{console.error('Error fetching data:', error);})
  
  
      this.dropdownService.fillLookupOptionsNew(AppSettings.dealTeamSwiftRole,'Role').then(
        (response:any) => {
          // Success block
          this.thirdColumnItemList=response;
        }).catch((error:any) =>{console.error('Error fetching data:', error);})
  }

  // tslint:disable-next-line: max-line-length
  private _retrieveOtherFormsStatus(crossBorderBaseUrl: string, cafBaseUrl: string, dealwbsBaseUrl: string, closingChecklistBaseUrl: string) {
  // forkJoin([
  //   // tslint:disable-next-line: max-line-length
  //   this.engagementHealthObjectService.retrieveFormStatusObject(AppSettings.crossBorderFormList, this.engagementHealthObject.myDealId, crossBorderBaseUrl),
  //   this.engagementHealthObjectService.retrieveFormStatusObject(AppSettings.cafFormList, this.engagementHealthObject.myDealId, cafBaseUrl),
  //   // tslint:disable-next-line: max-line-length
  //   this.engagementHealthObjectService.retrieveFormStatusObject(AppSettings.dealWbsSetupFormList, this.engagementHealthObject.myDealId, dealwbsBaseUrl),
  //   // tslint:disable-next-line: max-line-length
  //   this.engagementHealthObjectService.retrieveFormStatusObject(AppSettings.closingChecklistFormList, this.engagementHealthObject.myDealId, closingChecklistBaseUrl)
  // ])
  forkJoin([
    // tslint:disable-next-line: max-line-length
    this.engagementHealthObjectService.retrieveFormStatusObjectEHO(AppSettings.crossBorderFormList, this.myDealId),
    this.engagementHealthObjectService.retrieveFormStatusObjectEHO(AppSettings.dealWbsSetupFormList, this.myDealId),
    this.engagementHealthObjectService.retrieveFormStatusObjectEHO(AppSettings.closingChecklistFormList, this.myDealId)
  ])
    .subscribe(([statusCrossBorder,statusWbs,statusclosingChecklist]) => {
      let statusCaf={
        Id:1,
        status:"Pending"
      }      
      const folderUrlCaf:any = this._getFolderUrlCaf(statusCaf);
      const folderUrlCrossBorder:any = this._getFolderCrossBorder(statusCrossBorder);
      // forkJoin([
      //   this.fileService.getFiless(folderUrlCaf, cafBaseUrl, AppSettings.cafFormList),
      //   this.fileService.getFiless(folderUrlCrossBorder, crossBorderBaseUrl, AppSettings.crossBorderFormList)])
      forkJoin([
        this.fileService.getFilesDynamic(statusCrossBorder?.Id.toString(), crossBorderBaseUrl, AppSettings.crossBorderFormList)])
        .subscribe(([crossBorderAttachments]) => {
          this.spinnerService.hideAutoSaveSpinner();
          let cafAttachments:any=[];
        this.caffilesList = this.engagementHealthObjectServiceHelper.mapFilesResponseToObject(
          cafAttachments, folderUrlCaf, cafBaseUrl, AppSettings.cafFormList);
        this.crossBorderfilesList = this.engagementHealthObjectServiceHelper.mapFilesResponseToObject(
          crossBorderAttachments, folderUrlCrossBorder, crossBorderBaseUrl, AppSettings.crossBorderFormList);
        this.sidebarEngagementContentBreakdownComponent.setSidebarPropertiesCaf(this.caffilesList);
        this.sidebarEngagementContentBreakdownComponent.setSidebarPropertiesCrossBorder(this.crossBorderfilesList);
      });
      // if (statusCrossBorder) {
      //   this.sidebarEngagementContentBreakdownComponent.setAvailableFormStatus(AppSettings.crossBorderFormList,
      //     statusCrossBorder.status, statusCrossBorder.Id);
      // }
      // if (statusCaf) {
      //   this.sidebarEngagementContentBreakdownComponent.setAvailableFormStatus(AppSettings.cafFormList, statusCaf.status, statusCaf.Id);
      // }
      if (statusWbs) {
        this.sidebarEngagementContentBreakdownComponent.setAvailableFormStatus(AppSettings.dealWbsSetupFormList,
          statusWbs.status, statusWbs.Id);
      }
      if (statusclosingChecklist) {
        this.sidebarEngagementContentBreakdownComponent.setAvailableFormStatus(AppSettings.closingChecklistFormList,
          statusclosingChecklist.status, statusclosingChecklist.Id);
      }
    });
  }

  private _setChildComponents(dealTeamList:any) {
    this.headerComponent.setEngagementTitle(this.engagementHealthObject);
    this.sidebarEngagementContentBreakdownComponent.setSidebarPropertiesNewDeal(this.engagementHealthObject, this.newDealfilesList);
    if(!this.isCurrentUserAdmin)
    this.dealTeamDetailsComponent.setDealTeamDetails(dealTeamList);
    this.userPermisions.isAdmin= Boolean(this.currentUser.groups.find(group => group === AppSettings.myDealAdminGroup))
    this.headerComponent.setProperties(this.engagementHealthObject,this.userPermisions.isAdmin);
    if(Boolean(this.currentUser.groups.find(group => group === AppSettings.myDealAdminGroup)))
    {
      this.dealTeamAuditlogComponent.getAuditLogDetails(this.engagementHealthObject.myDealId,this.engagementHealthObject.engagementName)
    }
    this.engagementHealthDetailsComponent.setEngagementHealth(this.engagementHealthObject, this.userPermisions, dealTeamList);
    this.thirdPartyCodesComponent.setThirdPartyCodes(this.engagementHealthObject);
    this.cutLoadingAnimation();
  }

  private _isDCFMemberInAdGroup(email:string,groups:Array<any>): boolean {
    return !!groups.find(group => group.userPrincipalName.toLowerCase() === email.toLowerCase());
  }
  private _setPermissions(dealTeamList: Array<any>) {
    //this.userPermisions.isComplianceUser = Boolean(this.currentUser.groups.find(group => group === AppSettings.dcfComplianceGroup));
    this.userPermisions.isDealTeamUser = Boolean(dealTeamList.find(user => user.Email === this.currentUser.email));
    this.userPermisions.isAdmin = Boolean(this.currentUser.groups.find(group => group === AppSettings.myDealAdminGroup));
  }

  private _getFormBaseUrl(availableForms: any, listName: any):any {
    return availableForms.find((form:any)=> form.availableFormListName === listName).availableFormSiteUrl;
  }

  private _getFolderUrlNewDeal() {
    return this._getFolderUrl(
      AppSettings.attachmentsListNewDeal, AppSettings.attachmentsFolderNameNewDeal, this.engagementHealthObject.newDealItemId);
  }

  private _getFolderUrlCaf(statusCaf:any) {
    if (!statusCaf) {
      return null;
    }
    return this._getFolderUrl(
      AppSettings.attachmentsListCaf, AppSettings.attachmentsFolderNameCaf, statusCaf.Id);
  }

  private _getFolderCrossBorder(statusCrossBorder:any) {
    if (!statusCrossBorder) {
      return null;
    }
    return this._getFolderUrl(
      AppSettings.attachmentsListCrossBorder, AppSettings.attachmentsFolderNameCrossBorder, statusCrossBorder.Id);
  }

  private _getFolderUrl(attachmentList: string, folderName: string, formId: number) {
    const folderId = folderName + formId;
    return attachmentList + '/' + folderId;
  }

  private _setFormId() {
    this.formId = Number(this.route.snapshot.queryParams.item_id);
  }
 
  private _deleteDealTeamRow(dealTeamRow: PeoplePickerSelectedRow) {    
    const dealTeamAuditObject: object = {
     DealTeamMember: dealTeamRow.item.usersSelected[0].DisplayText,
     FormId:this.myDealFormId,
     CreatedBy:this.currentUserName,
     Action:dealTeamRow.actionType,
     FormName:AppSettings.engagementHealthList
    };
//Need to verify below deleteListItem
    //this.mainService.deleteListItem(AppSettings.dealTeamTableList,dealTeamRow.item.itemId),
   forkJoin ([this.mainService.deleteListItem(AppSettings.dealTeamTableList, this.myDealFormId.toString(), dealTeamRow.item.itemId.toString()),
   this.mainService.createListItemEHO(AppSettings.staticDealTeamAuditLog, dealTeamAuditObject)])
   .subscribe(([response, auditLogId]) => {
     this._updateDealTeamMembers(this.dealTeamValues);
   })
 }

 private _storeDealTeamRow(dealTeamRow: PeoplePickerSelectedRow) {
   const objectToSend = this._createDealTeamObject(dealTeamRow.item);
   const addDealTeamAuditObject: object = {
     DealTeamMember: dealTeamRow.item.usersSelected[0].displayName,
     FormId:objectToSend.FormId,
     CreatedBy:this.currentUserName,
     Action:dealTeamRow.actionType,
     FormName:AppSettings.engagementHealthList
     };
   if (!dealTeamRow.item.itemId) {
     this.mainService.createListItem(AppSettings.dealTeamTableList, objectToSend)
     .then((itemId) => {
       this.dealTeamComponent.setItemPeoplePicker(itemId, dealTeamRow.item.index);
       this.mainService.createListItemEHO(AppSettings.staticDealTeamAuditLog, addDealTeamAuditObject).subscribe((AuditLog) => {
       this._updateDealTeamMembers(this.dealTeamValues,true,dealTeamRow.item.usersSelected[0].mail);
       });
       
     })
     .catch(error => {
     });
   } else {
    var isNameChanged=false;
     this.mainService.getAuditLogDealTeamMemebersEHO(AppSettings.dealTeamTableList, this.myDealFormId).subscribe((response:any) => {
       var dealTeamName;
       response?.forEach((element:any) => {
         if(objectToSend.Name!=element.Name && dealTeamRow.item.itemId==element.ID)
         {
           dealTeamName=element.Name;
           const removeDealTeamAuditObject: object = {
             DealTeamMember: dealTeamName,
             FormId:objectToSend.FormId,
             CreatedBy:this.currentUserName,
             Action:DynamicTableAction.Delete,
             FormName: AppSettings.engagementHealthList
             };
            this.mainService.createListItemsEHO(AppSettings.dealTeamAuditLog,[removeDealTeamAuditObject,addDealTeamAuditObject] )
            isNameChanged = true;
         }
     });
        
       var initiatorEmail = "";
       var dealTeamRowItemId = (dealTeamRow.item.itemId as any)?.ID || dealTeamRow.item.itemId 
       objectToSend.ID = dealTeamRowItemId;
       this.mainService.updateListItem(AppSettings.dealTeamTableList, dealTeamRowItemId, objectToSend)
         .then((response:any) => {
           if(isNameChanged)
             initiatorEmail = objectToSend.Email
          this._updateDealTeamMembers(this.dealTeamValues,isNameChanged,initiatorEmail);
     })
     .catch(error => {
     });
       });
     }
 }

 private _updateDealTeamMembers(responseDealTeamTable:any,sendNotification:boolean=false,notifierEmail:string="") {
  const myDealDataObject =  { DealTeamMembersDisplayNames : "",DealTeamMembers:""};
  let dealTeamMembers:any = [];
  let dealTeamMemberEmails:any = [];
   if (responseDealTeamTable && responseDealTeamTable.length > 0) {
    responseDealTeamTable.forEach( (item:any) => {
         dealTeamMembers = dealTeamMembers.concat(item.usersSelected[0].displayName);
         dealTeamMemberEmails = dealTeamMemberEmails.concat(item.usersSelected[0].mail);
       });
       const concatenatedString = dealTeamMembers.join(';#');
       myDealDataObject.DealTeamMembersDisplayNames = concatenatedString;
       const emailString = dealTeamMemberEmails.join(';#');
       myDealDataObject.DealTeamMembers =emailString;
   }
  this.saveInProgress = true;
  Promise.all([this.mainService.updateForm(AppSettings.myDealFormList, this.myDealFormId, myDealDataObject,"EHO"),
               this.mainService.updateForm(AppSettings.formListName, this.engagementHealthObject.newDealItemId.toString(), myDealDataObject,"EHO",false,sendNotification,notifierEmail,this.formId.toString())])
  .then( () => {
    this.saveInProgress = false;
	  
					 
	   
  })
  .catch(error => {
  });

}

private _createDealTeamObject(dealTeamRow: PeoplepickerDynamicTableItem) {
  const dealteamObject = new DealTeamObject();
    // dealteamObject.NameId = dealTeamRow.idUsersSelected[0];
    // dealteamObject.RoleId = Number(dealTeamRow.optionsSelectedFirstColumn.key);
    // dealteamObject.PursuitRoleId = Number(dealTeamRow.optionsSelectedSecondColumn.key);
    // dealteamObject.SwiftRoleId =  Number(dealTeamRow.optionsSelectedThirdColumn.key);
    // dealteamObject.FormId = this.myDealFormId;
    dealteamObject.Name = dealTeamRow.usersSelected[0].displayName;
    dealteamObject.Role = String(dealTeamRow.optionsSelectedFirstColumn.value);
    dealteamObject.PursuitRole = String(dealTeamRow.optionsSelectedSecondColumn.value);
    dealteamObject.SwiftRole =  String(dealTeamRow.optionsSelectedThirdColumn.value);
    dealteamObject.FormId = this.myDealFormId.toString();
    dealteamObject.Email = dealTeamRow.usersSelected[0].mail;

    return dealteamObject;

  return dealteamObject;
}
private _checkDealTeamTypeEvent(dealTeamRow: PeoplePickerSelectedRow) {
  if (dealTeamRow.actionType === DynamicTableAction.Add) {
    this._storeDealTeamRow(dealTeamRow);
  } else {
    this._deleteDealTeamRow(dealTeamRow);
  }
}

 onDealTeamRowSelection(dealTeamRow: PeoplePickerSelectedRow) {
    if (this.formId) {
      this._checkDealTeamTypeEvent(dealTeamRow);
    }
  }

  getDealTeamRowsComplete(): Array<PeoplepickerDynamicTableItem> {
    const rowsCompleted: Array<PeoplepickerDynamicTableItem> = [];
    if (this.dealTeamValues) {
      this.dealTeamValues.forEach(row => {
        if (row && row.isComplete()) {
          rowsCompleted.push(row);
        }
      });
    }
    return rowsCompleted;
  }

  setDealTeamValues(dealTeamValues: PeoplepickerDynamicTableItem[]) {
    this.dealTeamValues = dealTeamValues;
  }

  setUpdateDealTeam(updateDealTeamTable:any) {
    this.updateDealTeamTable = updateDealTeamTable;
  }

  getUpdateDealTeam(): boolean {
    return this.updateDealTeamTable;
  }

  setItemPeoplePicker(itemId: number, index: number) {
    this.dealTeamComponent.setItemId(itemId, index);
  }

  private _isDCFAdmin(groups: Array<string>): boolean {
    const adminGroup = AppSettings.myDealAdminGroup;
    return !!groups.find(group => group === adminGroup);
  }

  checkIfUserIsAdmin(){
    return this._isDCFAdmin(this.currentUser.groups);
  }
}
