import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, catchError, debounceTime, delay, map, switchMap, tap } from 'rxjs';
import { AppSettings } from 'src/shared/app-settings';
import { SearchState } from '../models/userManagement-search';
import { MainServiceHelper } from 'src/shared/services/main-service-helper';
import { SpinnerService } from 'src/shared/services/spinner.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { GlobalHelperService } from '../globalHelper/global-helper.service';

@Injectable({
  providedIn: 'root'
})
export class CommonHelperService {
  loading$ = new BehaviorSubject<boolean>(false);
  search$ = new Subject<void>();
  static pageSizeDefault = 100; 
  getUserDataApiInProgress:boolean = false;
  reportDashboardList$ = new BehaviorSubject<any[]>([]);
  currentUserData$ = new BehaviorSubject<any>([]);
  total$ = new BehaviorSubject<number>(0);
  URL:any=AppSettings.crossBorder + '?item_id=';
  isUserInGroup$ = new BehaviorSubject<{}|null>(null);  
    state: SearchState;
    private header = new HttpHeaders({ 'content-type': 'application/json' });
  currentUser: any;
    constructor(private myhttp: HttpClient, private mainServiceHelper: MainServiceHelper, private spinnerService: SpinnerService, public globalHelperService: GlobalHelperService){}

  get searchTerm() { return this.state.searchTerm; }
    set searchTerm(searchTerm: string) {this._set({searchTerm}); }
    set sortData(sortData: any) { this._set({sortData}); }
    set filterDataList(filterDataList: Array<any>) { this._set({filterDataList}); }

    getSearchState(): SearchState{
      let obj: SearchState;
      //var currentAccessedPage = localStorage.getItem("CurrentAccessPage");
      var currentAccessedPage = this.globalHelperService.currentAccessPage;
      CommonHelperService.pageSizeDefault = 100;
      if(currentAccessedPage=="Admin"){
        obj = {
          page: 1,
          pageSize:25,
          searchTerm: '',
          sortData: {sortColumn: 'fullName', sortDirection: 'asc'},
          filterDataList: []
        };
      }
      else if(currentAccessedPage=="ContentManagement"){
        CommonHelperService.pageSizeDefault = 25;
        obj = {
          page: 1,
          pageSize: 25,
          searchTerm: '',
          sortData: { sortColumn:'', sortDirection:'' },
          filterDataList: []
        };
      }
      else if(currentAccessedPage=="TerminationDashboard"){
        CommonHelperService.pageSizeDefault = 100;
        obj = {
          page: 1,
          pageSize: 25,
          searchTerm: '',
          sortData: { sortColumn:'', sortDirection:'' },
          filterDataList: []
        };
      }
      else if(currentAccessedPage=="EngagementDashboard"){
        CommonHelperService.pageSizeDefault = 100;
        obj = {
          page: 1,
          pageSize: AppSettings.pageSizeDefault,
          searchTerm: '',
          sortData: {sortColumn: 'engagementId', sortDirection: 'desc'},
          filterDataList: []
        };
      }
      else{
        CommonHelperService.pageSizeDefault = 100;
        obj = {
          page: 1,
          pageSize:100,
          searchTerm: '',
          sortData: {sortColumn:'created', sortDirection: 'desc'},
          filterDataList: []
        } 
      }
      return obj;
    }

    sort(column: any, direction: string, reportDashboardList: Array<any>): Array<any> {
      //admin service
      if(column == "iD" && direction === "asc") //ascending order
        return reportDashboardList.sort((a, b) => a.iD - b.iD);
      else if(column == "iD" && direction === "desc") //descending order
        return reportDashboardList.sort((a, b) => b.iD - a.iD);

      //content service and termination service
        if (column == "ID" && direction === "asc") //ascending order
        return reportDashboardList.sort((a, b) => a.ID - b.ID);
      else if (column == "ID" && direction === "desc") //descending order
        return reportDashboardList.sort((a, b) => b.ID - a.ID);
      if (column == "FormID" && direction === "asc") //ascending order
        return reportDashboardList.sort((a, b) => a.FormID - b.FormID);
      else if (column == "FormID" && direction === "desc") //descending order
        return reportDashboardList.sort((a, b) => b.FormID - a.FormID);

      if (direction !== '') {
        reportDashboardList = [...reportDashboardList].sort((a, b):any => {
          const res = this.compare(a, b,column);  
         // const directionvalue=localStorage.getItem("CurrentAccessPage")=="Admin"?'asc':'desc';
          return direction === 'asc' ? res : -res;
        });       
      }
      return reportDashboardList;
    }
  showSpinner(PageSource?:string){
    let spinnerId=document.getElementById('loading',) as HTMLInputElement;
    spinnerId.style.display = 'block';
    if(PageSource && PageSource==AppSettings.PageSource.TerminationDashboard){
      spinnerId.style.top = "60%"
    }
    document.body.style.color="gray";
    document.body.style.pointerEvents="none";

  }
  hideSpinner(){
    let spinnerId=document.getElementById('loading',) as HTMLInputElement;
    spinnerId.style.display = 'none';
    document.body.style.color="black";
    document.body.style.pointerEvents="auto";
  }
    compare(v1:any, v2:any,column:any) {
     let v1Data=v1[column];
      let v2Data=v2[column];

      var DateFields = ['TerminationDate',,'SignatureDate','DCFKCRemovedDate',
    'SupervisionGroupRemovedDate','SmarshTextCaptureRemovedDate' ,'OutlookRemovedDate',
    'IdentifyIndividualTermedDate', 'Modified',
    'SupervisorOrgChartRemovalDate','CSIRetiredDate','TermedEmployeeMovedDate']
      // if(column=='ID')
      // {
      //   return v1Data < v2Data ? -1 : v1Data > v2Data ? 1 : 0;
      // }
      var minDate = moment("0001-01-01");
      if (column == 'created' || column == 'modified' || column == 'discontinuedOn' || column == 'series24DateApproval' || column == 'DCFDateApproval'
      || column == 'Modified'||column=='Created' || column == 'ModifiedOn'|| DateFields.includes(column))
      {
        let v1Date = v1Data != null && v1Data != "" && v1Data != "-" ? moment(v1Data) : minDate;
        let v2Date = v2Data != null && v2Data != "" && v2Data != "-" ? moment(v2Data) : minDate ;
        return v1Date < v2Date ? -1 :  v1Date >  v2Date ? 1 : 0;
      }
      else{
        v1Data = v1Data != null && v1Data != "" && v1Data != "-" ? v1Data:"zzzz";
        v2Data = v2Data != null && v2Data != "" && v2Data != "-" ? v2Data:"zzzz";
        return v1Data.toUpperCase() < v2Data.toUpperCase() ? -1 : v1Data.toUpperCase() > v2Data.toUpperCase() ? 1 : 0;
      }
    }
  
    private _set(patch: Partial<SearchState>) {
      Object.assign(this.state, patch);
      if (typeof(patch.searchTerm) !== 'undefined') {
        this.search$.next();
      }
    }

    private _getAssignedEngagements(responseEngagements: any, assignedMyDealIds: number[]): any[] {
      return responseEngagements.filter((eng:any) => assignedMyDealIds.includes(eng.myDealId));
    }

    refreshToken(){
      localStorage. clear();
      window.location.reload();
    }

    isTokenExpired(){
      // let currentAccessedPage=localStorage.getItem("CurrentAccessPage")?.toString();
      let currentAccessedPage=this.globalHelperService.currentAccessPage?.toString();
      let currentAccessedFormID:any = "0";
      switch(currentAccessedPage)
      {
          case "NewDealForm":
              // currentAccessedFormID = localStorage.getItem("formId")?.toString();
              currentAccessedFormID = this.globalHelperService.formID?.toString();
              break;
              case "DealWBSForm":
                // currentAccessedFormID = localStorage.getItem("formId")?.toString();
                currentAccessedFormID = this.globalHelperService.formID?.toString();
                break;
                case "ClosingChecklistForm":
                  // currentAccessedFormID = localStorage.getItem("formId")?.toString();
                  currentAccessedFormID = this.globalHelperService.formID?.toString();
                  break;
        case "TerminationForm":
          // currentAccessedFormID = localStorage.getItem("tformId")?.toString();
          currentAccessedFormID = this.globalHelperService.formID?.toString();
          break;
        case "CrossBorderForm":
          currentAccessedFormID = localStorage.getItem("form_id")?.toString();
          break;
        case "EHO":
          currentAccessedFormID = localStorage.getItem("eho_id")?.toString();
          break;
        default:
          currentAccessedFormID = "0";
      }
      localStorage.clear();
      if(currentAccessedPage=="Dashboard"){
      window.location.href =AppSettings.DashboardURL;
      }
      else if(currentAccessedPage=="EngagementDashboard"){
        window.location.href =AppSettings.Engagementdashboard;
      }
      else if (currentAccessedPage == "TerminationDashboard") {
          window.location.href = AppSettings.terminationFormDashboard;
      }
      else if(currentAccessedPage=="CrossBorderForm"){
        window.location.href =currentAccessedFormID?.toString()=="0"||currentAccessedFormID?.toString()==""||currentAccessedFormID?.toString()==null?AppSettings.crossBorder:AppSettings.crossBorder+"?item_id="+currentAccessedFormID;
      }
      else if(currentAccessedPage=="NewDealForm"){
        window.location.href =currentAccessedFormID?.toString()=="0"||currentAccessedFormID?.toString()==""||currentAccessedFormID?.toString()==null?AppSettings.newDeal:AppSettings.newDeal+"?id="+currentAccessedFormID;
      }
      else if (currentAccessedPage == "TerminationForm") {
          window.location.href = currentAccessedFormID?.toString() == "0" || currentAccessedFormID?.toString() == "" || currentAccessedFormID?.toString() == null ? AppSettings.terminationForm : AppSettings.terminationForm + "?id=" + currentAccessedFormID;
      }
      else if(currentAccessedPage=="DealWBSForm"){
        window.location.href =currentAccessedFormID?.toString()=="0"||currentAccessedFormID?.toString()==""||currentAccessedFormID?.toString()==null?AppSettings.dealWbsForm:AppSettings.dealWbsForm+"?id="+currentAccessedFormID;
      }
      else if (currentAccessedPage == "ClosingChecklistForm") {
          window.location.href = currentAccessedFormID?.toString() == "0" || currentAccessedFormID?.toString() == "" || currentAccessedFormID?.toString() == null ? AppSettings.ClosingCheckListForm : AppSettings.ClosingCheckListForm + "?id=" + currentAccessedFormID;
      }

      else if(currentAccessedPage=="EHO"){
        window.location.href =currentAccessedFormID?.toString()=="0"||currentAccessedFormID?.toString()==""||currentAccessedFormID?.toString()==null?AppSettings.EnagagementHealthObject:AppSettings.EnagagementHealthObject+"?item_id="+currentAccessedFormID;
      }
      else if(currentAccessedPage=="Admin"){
        window.location.href =AppSettings.AdminURL;
      }
      else if(currentAccessedPage=="ContentManagement"){
        window.location.href =AppSettings.ContentURL;
      }
      //Commented for now, will be removed later after observations
      // else{
      //   window.location.href =AppSettings.DashboardURL;
      // }
    }

    getActiveRecords(dataList:any){
      dataList= dataList.filter((x:any)=>x.isActive!="No");
      return dataList;
     }
     showFade(){
      let spinnerId=document.getElementById('loadingfade',) as HTMLInputElement;
      spinnerId.style.display = 'block';
      document.body.style.overflowY='hidden';
    }
    ValidateFileType(fileExt: string): boolean {
      let isFileTypeValid:boolean=false;
      let userAllowedExtensionsList=AppSettings.FileExtensionLists.toString().split(",");
      userAllowedExtensionsList.forEach((x:any)=>{
        if(x.includes(fileExt)){
           isFileTypeValid=true;
        }
      })
      return isFileTypeValid;
    }
    removeInvalidDateClass(){
       //Added below logic to Handle Date field errors
       if(document.getElementsByClassName("msg-invalid-date")!=null&&document.getElementsByClassName("msg-invalid-date")!=undefined){
        var InvalidDateList:any=document.getElementsByClassName("msg-invalid-date");
        for(let i=0;i<=InvalidDateList.length-1;i++){
          document.getElementsByClassName("msg-invalid-date")[i].remove();
           i=-1;
        }
        //document.getElementsByClassName("msg-invalid-date")[0].remove()
      }
    }

 // Function to fetch data from the API
 private fetchUserDataFromAPI(): Observable<any[]> {
  // Replace the URL with your actual API endpoint
  this.getUserDataApiInProgress = true;
  let userId=localStorage.getItem("userProfileId")?.toString();
  return this.GetUserRoleGroups(userId).pipe( 
    map((response:any) => {
      this.getUserDataApiInProgress = false;
      this.currentUser = this.mainServiceHelper.createCurrentUserObject(response);
      return this.currentUser;
    }),
    catchError((err:any) => {
      this.getUserDataApiInProgress = false;
      if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.isTokenExpired(); }
      this.spinnerService.hide();
      console.log("Error" + err);
      throw err;
    })
  );
}

GetUserRoleGroups(userId:any):Observable<any>{
  var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.RoleManagement)
  const url = envURL +'/api/UserGroups/GetUserGroupsByEmail?email='+userId;
  return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
  'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
}

// Function to get user data, either from cache or API
getUserData(): Observable<any> {
  if (this.currentUserData$.value.length === 0) {
    if(!this.getUserDataApiInProgress)
    {
      this.fetchUserDataFromAPI().pipe(
        tap(data => this.currentUserData$.next(data))
      ).subscribe(); // This subscribes to the Observable returned by fetchUserDataFromAPI
    }
  }
  return this.currentUserData$.asObservable(); // Return as Observable to prevent external subjects from emitting values
}

updateUserGroupMembership(isInGroup: any) {
  this.isUserInGroup$.next(isInGroup);
}
getUserGroupMembership() {
  return this.isUserInGroup$.asObservable();
}
}
